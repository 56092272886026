import React from "react"

import PropTypes from "prop-types"
import classNames from 'classnames'
import styles from '@styles/components/section_title.module.scss'

import IcnTriangle from '@comp/atom/icn_triangle'

const propTypes = {
  wrapClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelEn: PropTypes.string.isRequired,
}

const SectionTitle = ({
  wrapClass,
  label,
  labelEn
}) => {
  const classNameTitle = classNames([
    wrapClass,
    styles.title,
  ])

  return (
    <>
      <h1 className={classNameTitle}>
        <span className={styles.title__icn}><IcnTriangle /></span>
        <span className={styles.title__label__en}>{labelEn}</span>
        <span className={styles.title__label}> {label} </span>
      </h1>
    </>
  )
}

SectionTitle.propTypes = propTypes
export default SectionTitle
