import React from "react"

import PropTypes from "prop-types"
import styles from '@styles/components/contact_block.module.scss'

import Nl2br from '@comp/util/nl2br'
import BtnBox from '@comp/btn_box'
import SectionTitle from '@comp/section_title'


const propTypes = {
  children: PropTypes.node,
  copy: PropTypes.string,
  txt: PropTypes.string
}
const ContactBlock = ({
  children,
  copy,
  txt
}) => {
  return (
    <>
      <SectionTitle 
        wrapClass={styles.title}
        label={'お問い合わせ'}
        labelEn={'CONTACT'}
      />
      { 
        children ? (
          <> {children} </>
        ) : (
          <>
            <h2 className={styles.copy}><Nl2br txt={copy} /></h2>
            <p className={styles.txt}><Nl2br txt={txt} /></p>
          </>
        ) 
      }
      <div className={styles.btn}>
        <BtnBox
          to={'/contact/'}
        >
          お問い合わせ
        </BtnBox>
      </div>
    </>
  )
}
ContactBlock.propTypes = propTypes
export default ContactBlock
