import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styles from '@styles/components/page_copy.module.scss'

const propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ])
}

const baseDelay = 70
const letterElment = (itm, count, delay) => {
  return typeof itm === 'string' ? itm.split('').map( (str,index) => {
    const d = ((count + index) * (delay ? delay : baseDelay)) / 1000
    return (
      <span key={index} className={styles.letter} style={{transitionDelay:`${d}s`}}>{str}</span>
    )
  }) : itm
}

const PageCopy = ({
  children,
  visible,
  delay
}) => {
  const elm = useMemo( () => {
    if(typeof children === 'object') {
      return children.map( (itm, index) => {
        return letterElment(itm, index, delay)
      })
    } else {
      return letterElment(children, 1, delay)
    }
  },[children, delay])

  return (
    <>
      <span className={styles.wrap} data-visible={visible}>
        {elm}
      </span>
    </>
  )
}

PageCopy.propTypes = propTypes
export default PageCopy
