import React, { useRef, useMemo, useState, useCallback, useEffect } from "react"
import { graphql } from "gatsby"
import classNames from 'classnames/bind'

import styles from '@styles/pages/index.module.scss'

import {useWindowSize} from "@/hooks/use_window_size"

import Layout from '@comp/layout'
import SEO from '@comp/seo'

import TransitionAnimationLink from '@comp/transition_animation_link'
import BtnDetail from '@comp/btn_detail'
import ContactBlock from '@comp/contact_block'
import SectionTitle from '@comp/section_title'
import PageCopy from '@comp/page_copy'
import Logo from '@comp/atom/logo'

const cx = classNames.bind(styles)

const sliderList = [1,2,3,4,5,6]
const sliderListSp = [1,2,3,4]

const IndexPage = ({ 
  location,
  data
}) => {
  const {device} = useWindowSize()
  const sliderTimer = useRef(null)
  const slideImg = useRef(0)
  const slideImgSp = useRef(0)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentSlideSp, setCurrentSlideSp] = useState(0)

  const [openingActionStart, setOpeningActionStart] = useState(false)
  const [openingActionDone, setOpeningActionDone] = useState(false)

  const recentNews = useMemo( () => {
    const post = data.allMarkdownRemark.edges[0]?.node.frontmatter
    return post ? {
      ...post,
      datetime: post.date.replace(/\//g,'-')
    }: null;
  },[data.allMarkdownRemark.edges])

  const slide = useMemo( () => {
    return sliderList.map( (id, i) => {
      const className = cx({
        [`mv__itm${id}`]: true,
        isCurrent: i === currentSlide
      })
      return (
        <div key={i} className={className}>
          { i === 0 ? <div className={styles.mv__logo}><Logo color="white" /></div> : null }
        </div>
      )
    })
  },[currentSlide])

  const slideSp = useMemo( () => {
    return sliderListSp.map( (id, i) => {
      const className = cx({
        mv__sp__img: true,
        isCurrent: i === currentSlideSp
      })
      return (
        <img
          key={i}
          src={`/images/home/sp/home-img-mv${id}.jpg`}
          alt=""
          className={className}
        />
      )
    })
  },[currentSlideSp])

  const slideTimer = useCallback( () => {
    const sliderLength = sliderList.length - 1
    const sliderSpLength = sliderListSp.length - 1
    
    if(slideImg.current === sliderLength) {
      slideImg.current = 0
    } else {
      slideImg.current = slideImg.current + 1
    }
    
    if(slideImgSp.current === sliderSpLength) {
      slideImgSp.current = 0
    } else {
      slideImgSp.current = slideImgSp.current + 1
    }
    setCurrentSlide(slideImg.current)
    setCurrentSlideSp(slideImgSp.current)
  },[setCurrentSlide, setCurrentSlideSp, slideImg, slideImgSp])


  const startOpeningAnimation = useCallback( () => {
    setOpeningActionStart(true)
    if(!sliderTimer.current) {
      sliderTimer.current = setInterval(slideTimer,4000)
    }
  },[slideTimer])

  const onOpeningActionDone = useCallback( (e) => {
    if(e.propertyName === 'clip-path') {
      if(!openingActionDone) {
        setOpeningActionDone(true)
      }
    }
  },[openingActionDone])

  const classNameMv = useMemo( () => {
    return cx({
      mv: true,
      isOpen: openingActionStart
    })
  },[openingActionStart])

  const sectionStatus = useMemo( () => {
    return openingActionDone ? 'animated' : false
  },[openingActionDone])

  useEffect( () => {
    let startTimer = null
    if(device.isPc) {
      startTimer = setTimeout(startOpeningAnimation,3000)
    } else if(device.isTab || device.isMobile) {
      startTimer = setTimeout(() => {
        startOpeningAnimation()
        setOpeningActionDone(true)
      },300)
    }
    
    return () => {
      clearTimeout(startTimer)
      clearInterval(sliderTimer.current)
      sliderTimer.current = null
    }
  },[device, device.isPc, setOpeningActionDone, slideTimer, startOpeningAnimation])

  return (
    <Layout location={location} pageName="home" headerStatus={openingActionDone ? 'animated': null}>
      <SEO
        type={'website'}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.contents__top}>

        <div className={classNames([styles.section__img__sp,styles.mv__sp])} role="presentation">
          { slideSp }
        </div>

        <div className={styles.contents__top__body}>

          <div className={styles.copy}>
            <h1 className={styles.copy__main}>
              <PageCopy visible={openingActionDone}>可能性を<br className='ex-onlysp' />仕込み、<br />未来を醸す</PageCopy>
            </h1>
            <p className={styles.copy__overview} data-visible={sectionStatus} data-delay="0">MIRAI SAKE COMPANYは、<br className='ex-onlypc' />原料の生産から日本酒のブランド作り、販売までを一気通貫で考え<br />伝統にとらわれない新たな手法で日本酒の未来を切り拓く会社です。</p>
          </div>

          <section className={styles.news} data-visible={sectionStatus} data-delay="1">
            <SectionTitle
              label={'最新の取り組み'}
              labelEn={'NEWS'}
            />
            <div className={styles.news__inner}>
              {
                recentNews ? (
                  <article className={styles.news__block}>
                    <TransitionAnimationLink to={ recentNews.slug }>
                      <div className={styles.news__block__img}>
                        <img src={ recentNews.thumbnailImg } alt=""/>
                      </div>
                      <div className={styles.news__block__body}>
                        <h1 className={styles.news__block__title}>{ recentNews.title }</h1>
                        <time className={styles.news__block__date} dateTime={ recentNews.datetime }>{ recentNews.date }</time>
                      </div>
                    </TransitionAnimationLink>
                  </article>
                ): null
              }
              <div className={styles.news__btn}>
                <BtnDetail
                  to={'/news/'}
                >
                  取り組み <br className='ex-onlypc' />一覧を見る
                </BtnDetail>
              </div>
            </div>
          </section>
          <div className={styles.section__img__sp} role="presentation">
            <img
              src="/images/home/sp/home-img-project01.jpg"
              alt=""
            />
          </div>
          <section className={styles.project} data-visible={sectionStatus} data-delay="2">
            <SectionTitle
              label={'事業内容'}
              labelEn={'PROJECT'}
            />
            <h2 className={styles.project__copy}>原料から流通、情報まで<br className='ex-onlypc' />SAKEを取り巻く全てを解決する<br />ソリューション・カンパニー</h2>
            <p className={styles.project__overview}>MIRAI SAKE COMPANYは、<br className='ex-onlypc' />日本酒の生産〜消費者への提案まで幅広くカバーし、<br className='ex-onlypc' />日本酒産業の未来を作っていきます。</p>
            <ul className={styles.project__list}>
              <li className={styles.project__itm}>
                <span className={styles.project__itm__img}>
                  <img src="/images/home/logo-miraisake.svg" alt="未来日本酒店"/>
                </span>
                <span className={styles.project__itm__txt}>テーマ別でスター銘柄を集めたSAKEセレクトショップ</span>
              </li>
              <li className={styles.project__itm}>
                <span className={styles.project__itm__img}>
                  <img src="/images/home/logo-yummy.svg" alt="YUMMY SAKE"/>
                </span>
                <span className={styles.project__itm__txt}>人工知能を用いた味覚購買プラットフォーム</span>
              </li>
              <li className={styles.project__itm}>
                <span className={styles.project__itm__img}>
                  <img src="/images/home/logo-international.svg" alt="MIRAI SAKE INTERNATIONAL"/>
                </span>
                <span className={styles.project__itm__txt}>最高峰のSAKEが集まるセレクトショップを海外市場に展開</span>
              </li>
              <li className={styles.project__itm}>
                <span className={styles.project__itm__img}>
                  <img src="/images/home/logo-brewing.svg" alt="MIRAI SAKE BREWING"/>
                </span>
                <span className={styles.project__itm__txt}>酒造りの最先端が表現されたオリジナルSAKEブランド</span>
              </li>
              <li className={styles.project__itm}>
                <span className={styles.project__itm__img}>
                  <img src="/images/home/logo-farming.svg" alt="MIRAI RICE FARMING"/>
                </span>
                <span className={styles.project__itm__txt}>最高品質の酒造りを実現するための、自社米生産事業</span>
              </li>
              <li className={styles.project__itm}>
                <span className={styles.project__itm__img}>
                  <img src="/images/home/logo-keishou.svg" alt="未来酒造継承機構"/>
                </span>
                <span className={styles.project__itm__txt}>酒造の承継を希望するスポンサーと蔵元を繋ぐサービス</span>
              </li>
              <li className={styles.project__itm}>
                <span className={styles.project__itm__img}>
                  <img src="/images/home/logo-consulting.svg" alt="MIRAI SAKE CONSULTING"/>
                </span>
                <span className={styles.project__itm__txt}>日本酒産業に関する最先端の知見を様々な企業や自治体に提供</span>
              </li>
            </ul>
            <div className={styles.project__btn}>
              <BtnDetail
                to={'/project/'}
              >
                事業内容 <br className='ex-onlypc' />詳細を見る
              </BtnDetail>
            </div>
          </section>

          <div className={styles.section__img__sp} role="presentation">
            <img
              src="/images/home/sp/home-img-philosophy01.jpg"
              alt=""
            />
          </div>
          <section className={styles.philosophy} data-visible={sectionStatus} data-delay="3">
            <SectionTitle
              label={'企業理念'}
              labelEn={'PHILOSOPHY'}
            />
            <h2 className={styles.philosophy__copy}>日本酒の可能性を信じ、<br className='ex-onlypc' />世界中にSAKE文化を根付かせたい</h2>
            <p className={styles.philosophy__overview}>今や海外でも生産され飲まれるようになったSAKE。<br className='ex-onlypc' />しかし私たちは、SAKEの持つポテンシャルは大きく、<br className='ex-onlypc' />ビールやワインと肩を並べる50兆円規模と考えています。</p>

            <div className={styles.philosophy__btn}>
              <BtnDetail
                to={'/philosophy/'}
              >
                企業理念 <br className='ex-onlypc' />詳細を見る
              </BtnDetail>
            </div>
          </section>
          </div>
          <div className={classNameMv} onTransitionEnd={onOpeningActionDone}>
            <div className={styles.mv__inner}>
              { slide }
            </div>
          </div>
        </div>

        <div className={styles.contents__bottom} data-visible={sectionStatus} data-delay="4">
          <div className={styles.section__img__sp} role="presentation">
            <img
              src="/images/home/sp/home-img-member01.jpg"
              alt=""
            />
          </div>
          <div className={styles.inner}>
            <section className={styles.member}>
              <SectionTitle
                label={'メンバー'}
                labelEn={'MEMBER'}
              />
              <div className={styles.member__head}>
                <h2 className={styles.member__copy}>多様なメンバーが<br />一途にSAKEの<br className='ex-onlysp' />未来を創る</h2>
                <p className={styles.member__overview}>経験した業界、出身国や地域を超えて同志が集まり、<br className='ex-onlypc' />多様性を活かした事業展開を行っています。</p>
                <div className={styles.member__btn}>
                  <BtnDetail
                    to={'/member/'}
                  >
                    メンバー <br className='ex-onlypc' />詳細を見る
                  </BtnDetail>
                </div>
              </div>

              <div className={styles.member__img}>
                <img src="/images/home/home-img-member.jpg" alt=""/>
              </div>
            </section>
          </div>

          <div className={styles.section__img__sp} role="presentation">
            <img
              src="/images/home/sp/home-img-history01.jpg"
              alt=""
            />
          </div>

          <div className={styles.inner}>
            <section className={styles.history}>
              <SectionTitle
                label={'これまでの歩み'}
                labelEn={'HISTORY'}
              />
              <div className={styles.history__head}>
                <h2 className={styles.history__copy}>2013年の創業以来、<br />多くの共創実績が<br />生まれています</h2>
                <p className={styles.history__overview}>国内・国外、オンライン・オフラインを問わず、<br />常に新たな取り組みを模索し、<br />SAKEに関するコラボレーションを共創しています。</p>
                <div className={classNames([styles.isPc,styles.history__btn])}>
                  <BtnDetail
                    to={'/news/#history'}
                  >
                    これまでの歩み <br className='ex-onlypc' />詳細を見る
                  </BtnDetail>
                </div>
              </div>

              <ul className={styles.history__project}>
                <li className={styles.history__project__block}>
                  <span className={styles.history__project__head}>PARTNERSHIP</span>
                  <span className={styles.history__project__img}><img src="/images/home/home-history-partnership.png" alt="" className={styles.history__project__img01} /></span>
                  <span className={styles.history__project__txt}>47都道府県中、<br />40都府県の酒蔵と取引しています</span>
                </li>
                <li className={styles.history__project__block}>
                  <span className={styles.history__project__head}>COLLABORATION</span>
                  <span className={styles.history__project__img}><img src="/images/home/home-history-collaboration.png" alt="" className={styles.history__project__img02}/></span>
                  <span className={styles.history__project__txt}>YUMMY SAKEを<br />大手デベロッパーや自治体が採用</span>
                </li>
                <li className={styles.history__project__block}>
                  <span className={styles.history__project__head}>LICENCE</span>
                  <span className={styles.history__project__img}><img src="/images/home/home-history-licence.png" alt="" className={styles.history__project__img03}/></span>
                  <span className={styles.history__project__txt}>流通における最も幅広い酒類卸が<br />可能な全酒類卸売免許を保有</span>
                </li>
                <li className={styles.history__project__block}>
                  <span className={styles.history__project__head}>MEDIA</span>
                  <span className={styles.history__project__img}><img src="/images/home/home-history-media.png" alt="" className={styles.history__project__img04}/></span>
                  <span className={styles.history__project__txt}>先進的な<br className='ex-onlysp' />取組による<br />メディア露出も<br className='ex-onlysp' />多数</span>
                </li>
              </ul>
              <div className={classNames([styles.isSp,styles.history__btn])}>
                <BtnDetail
                  to={'/news/#history'}
                >
                  これまでの歩み 詳細を見る
                </BtnDetail>
              </div>

              <div className={styles.history__img}>
                <img src="/images/home/home-img-history.jpg" alt=""/>
              </div>
            </section>
          </div>

          <div className={styles.section__img__sp} role="presentation">
            <img
              src="/images/home/sp/home-img-contact01.jpg"
              alt=""
            />
          </div>

          <div className={styles.inner}>
            <section className={styles.contact}>
              <ContactBlock>
                <>
                  <h1 className={styles.contact__copy}>未来の<br className='ex-onlysp' />コラボレーターを<br />探しています</h1>
                  <p className={styles.contact__txt}>私たちの活動に関心を持っていただけましたら、下記フォームよりお問い合わせください。</p>
                </>
              </ContactBlock>

              <div className={styles.contact__img}>
                <img src="/images/home/home-img-contact.jpg" alt=""/>
              </div>
            </section>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage


export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {category: {eq: "news"}}}, limit: 1) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            date(formatString: "YYYY/MM/DD")
            thumbnailImg
          }
        }
      }
    }
  }
`